import React, {useEffect}from "react"
import { StaticImage } from "gatsby-plugin-image";
import HttpsRedirect from 'react-https-redirect';
import { navigate } from "@reach/router";

import AOS from 'aos';
import "./index.scss";
import 'animate.css';
import 'aos/dist/aos.css'; // You can also use <link> for styles


// markup
const IndexPage2 = () => {
  let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    window.location.protocol !== "https" ? navigate("https://innotive.fr/") : console.log("");
    const AOS = require("aos");
    AOS.init({
      once: true,
      duration: 1200,

    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });
  return (
      <div className="d-flex flex-column">
          <div className="head-container w-100 d-flex">
              <StaticImage src="../images/img3.jpg" className="img-head" />
              <div className="header-name scale-up-center">
                  INNOTIVE
              </div>
          </div>
        <div className="accompagner-container d-flex flex-column pt-5 justify-content-center align-items-center">
          <div className="accompagner-desc mb-5">
            <div className="d-contents bold-desc">INNOTIVE accompagne les propriétaires immobiliers dans l’élaboration de leur opération immobilière</div> en les assistant pour la réalisation de leur lotissement, division foncière, mise en copropriété...
          </div>
          <div className="accompagner-title mb-5">
            NOUS ACCOMPAGNONS
          </div>
          <div className="d-flex flex-row w-100 justify-content-evenly flex-wrap">
            <div className="d-flex flex-column accompagner-box">
                <svg className="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white"  class="bi bi-house-door svg-accompagner" style={{"align-self": "center"}} viewBox="0 0 16 16">
                  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
                </svg>
                <div className="head-accompagner">
                  Les particuliers
                </div>
                <div className="body-accompagner">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                Pour la réalisation de leur projet de lotissement, division foncière ou mise en copropriété.<br />
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
                Pour la valorisation et la cession de leurs actifs immobiliers : foncier libre, foncier bâti, immeubles...
                </div>
            </div>
            <div className="d-flex flex-column accompagner-box">
                <svg className="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white"  class="bi bi-house-door svg-accompagner" style={{"align-self": "center"}} viewBox="0 0 16 16">
                  <path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z"/>
                </svg>
                <div className="head-accompagner">
                  Les collectivités
                </div>
                <div className="body-accompagner">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                  Pour la réalisation clé en main de leur projet d’aménagement.<br />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                  Pour aider, conseiller leurs administrés souhaitant réaliser un projet de division foncière.
                </div>
            </div>
            <div className="d-flex flex-column accompagner-box mb-5 pb-3">
                <svg className="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white"  class="bi bi-house-door svg-accompagner" style={{"align-self": "center"}} viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                </svg>
                <div className="head-accompagner">
                  Les entreprises
                </div>
                <div className="body-accompagner">
                  Pour les accompagner dans la réflexion de
                  la valorisation et la cession des actifs immobiliers : foncier libre, foncier bâti, immeubles...
                </div>
            </div>
          </div>
        </div>
        <div className="atouts-container d-flex flex-wrap pb-5">
          <div className="d-flex flex-column atouts-box"  data-aos="fade-right" data-aos-mirror="true">
            <div className="head-atout">
              NOS ATOUTS
            </div>
            <div className="body-atout">
              • Optimisation de la valeur patrimoniale des biens de nos clients.<br />
              • Gestion des projets de A à Z.<br />
              • Souplesse et adaptation.
            </div>
          </div>
          <StaticImage src="../images/arrow.png" className="arrow-png-left" />
          <div className="d-flex innotive-circle">
            INNOTIVE
          </div>
          <StaticImage src="../images/arrow.png" className="arrow-png-right"/>
          <div className="d-flex flex-column atouts-box" data-aos="fade-left" data-aos-mirror="true">
            <div className="head-atout">
              NOTRE INNOVATION
            </div>
            <div className="body-atout">
              Création d’un nouveau service permettant aux particuliers, aux collectivités et aux entreprises de profiter de notre expertise pour monter et piloter
              leur projet immobilier.
            </div>
          </div>
        </div>
        
        <div className="method-container d-flex flex-column">
          <div className="mt-5 d-flex flex-column mb-5">
            <div className="method-container-title">
              NOTRE APPROCHE MÉTHODOLOGIQUE 
            </div>
            <div className="method-container-title">
              ET NOS COMPÉTENCES
            </div>
          </div>
          
          <div className="method-container-boxs d-flex">
            <div className="method-box d-flex flex-column"  data-aos="fade-left">
              <div className="d-flex flex-row method-title-container">
                <div className="title-method">
                1 - Diagnostic de l'existant et de son potentiel de valorisation
                </div>
              </div>
              <div className="method-desc mt-3">
                • Audit foncier.<br />
                • Analyse des documents d’urbanisme<br />
                et maîtrise des enjeux urbains.
                • Définition de vos objectifs et contraintes.<br />
                • Étude des différents scénarios de valorisation.<br />
              </div>
            </div>
            <div className="method-box d-flex flex-column"  data-aos="fade-down">
              <div className="d-flex flex-row method-title-container">
                <div className="title-method">
                2 - Assistance pour la cession de gré à gré de votre bien
                </div>
              </div>
              <div className="method-desc mt-3">
              • Réalisation d’un dossier de consultation. • Sélection et présentation des candidats,
              présentation du bien.<br />
              • Discussion et négociation avec les acquéreurs.<br />
              • Accompagnement jusqu’à la finalisation de la promesse et de l’acte authentique.
              </div>
            </div>
            <div className="method-box d-flex flex-column"  data-aos="fade-right">
              <div className="d-flex flex-row method-title-container">
                <div className="title-method">
                3 - Assistance à la réalisation de l'opération
                </div>
              </div>
              <div className="method-desc mt-3">
              • Réalisation du bilan prévisionnel financier.<br />
              • Discussion et validation du montage avec les services instructeurs (mairie, intercommunalité ...)<br />
              et les concessionnaires des réseaux (eau, électricité, égout, télécom...)
              • Pilotage des prestataires (architecte, géomètre, bureaux d’étude...)<br />
              • Obtention des autorisations administratives, affichage et constat.<br />
              • Financement des études et travaux. • Gestion et suivi des travaux.<br />
              • Commercialisation.
              </div>
            </div>
          </div>
        </div>
        <div className="travail-container d-flex flex-column pt-5 pb-5">
          <div className="d-flex flex-column flex-wrap travail-container-boxs justify-content-evenly mt-5 mb-5">
            <div className="d-flex flex-row justify-content-evenly">
              <div className="d-flex flex-column travail-box top-travail-box-left" data-aos="fade-left">
                <div className="box-title">
                  EN AMONT
                </div>
                <div className="travail-desc">
                  • Propriétaires privés<br />
                  • Collectivités<br />
                  • Entreprises
                </div>
                <StaticImage src="../images/arrow.png" className="arrow-top-left" />
              </div>
              <div className="d-flex flex-column travail-box top-travail-box-right" data-aos="fade-right">
                <div className="box-title">
                  EN AVAL
                </div>
                <div className="travail-desc">
                  • Promoteurs<br />
                  • Aménageurs<br />
                  • Constructeurs
                </div>
                <StaticImage src="../images/arrow.png" className="arrow-top-right" />
              </div>
            </div>
            <div className="innotive-travail-title" data-aos="zoom-in">
                INNOTIVE 
            </div>
            <div className="d-flex flex-row justify-content-center box-exchange"  data-aos="fade-down">
              <StaticImage src="../images/arrow.png" className="arrow-bot-left" />
              <StaticImage src="../images/arrow.png" className="arrow-bot-right" />
            </div>
            <div className="d-flex flex-column travail-box"  data-aos="fade-down">
              <div className="box-title">
                EN PARTENARIAT
              </div>
              <div className="travail-desc">
                • Notaires<br />
                • Géomètres<br />
                • Agences immobilières<br />
                • Bureaux d’étude
              </div>
            </div>
          </div>
        </div>
        <div className="contact-img d-flex">
          <div className="img-before-end">
            <StaticImage src="../images/head-1.jpeg" className="img-head" />
          </div>
          <div className="contact-band">
            <div className="d-flex flex-column contact-box">
              <div className="contact-title">
                Philippe BASCOUL
              </div>
              <div className="contact-desc">
              518 Avenue du Père Prévost<br />
              34090 MONTPELLIER<br />
              06.16.14.02.93<br />
              <div className="d-inline-block text-underline-pointer" onClick={(e) => {
                e.preventDefault();
                window.location = "mailto:philippe.bascoul@innotive.fr";
              }}>
                philippe.bascoul@innotive.fr
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default IndexPage2
